<template>
  <div class="checkemail-section" :class="$style.container">
    <EmailSentSvg />
    <h5 class="title-checkemail mt-5">{{ $t('verify.emailSentTitle') }}</h5>
    <p class="subtitle-checkemail">
      {{ $t('verify.emailSentSubTitle') }}
    </p>
    <div class="mt-2">
      <router-link to="/auth/login" class="mt-2">
        <a-button
          type="primary"
          size="large"
          class="text-center w-100 mt-5"
        >
          <strong>{{ $t('verify.emailSentButton') }}</strong>
        </a-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import EmailSentSvg from '@/components/Icons/EmailSent'
export default {
  components: {
    EmailSentSvg,
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>

<style lang="scss" scoped>
  .checkemail-section {
    text-align: center;
    max-width: 500px;
    margin: auto;
  }

  .title-checkemail {
    font-weight: 600;
    color: #015289;
    font-size: 31px;
  }

  .subtitle-checkemail {
    color: #000000;
    font-size: 16px;
  }
</style>
