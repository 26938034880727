<template>
  <svg width="222" height="222" viewBox="0 0 222 222" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="111" cy="111" r="111" fill="#F3F3F4"/>
    <circle cx="111.735" cy="111.735" r="72.0397" fill="white"/>
    <path d="M100.467 123.914L181.75 43.1172C182.918 45.2588 182.48 46.7676 181.75 49.2013L141.839 182.565C140.541 184.836 136.29 189.428 129.67 189.622C123.051 189.817 118.8 184.998 117.502 182.565L100.467 123.914Z" fill="#015289"/>
    <path d="M100.653 123.797L181.777 43.3204C179.508 42.2923 177.955 42.7677 175.45 43.5569L38.1953 86.7144C35.8796 88.0376 31.2564 92.2604 31.2894 98.566C31.3224 104.872 36.4841 108.729 39.0609 109.869L100.653 123.797Z" fill="#004980"/>
  </svg>
</template>

<script>
export default {
  name: 'EmailSentSvg',
}
</script>
